<template>
	<!-- 表格组件 -->
	<div>
		<div style="padding:10px 0">
			<span class="huizong">汇总： {{ totalIncome }}元</span>
			<span>提现中金额合计：{{ frozenBalance }}元</span>
		</div>
		<el-table border v-loading="loading" :data="dataList">
			<el-table-column
				label="代理商/机构编号"
				prop="agentNo"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="代理商/机构名称"
				prop="agentName"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="一级名称"
				prop="oneAgentName"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="一级编号"
				prop="oneAgentNo"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="上级名称"
				prop="parentAgentName"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="渠道"
				prop="channelNo"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.channelNo | channelFM }}
				</template>
			</el-table-column>
			<el-table-column
				label="账户类型"
				prop="accountType"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">{{
					dictTranslate(scope.row.accountType, dicts.ACCOUNT_TYPE)
				}}</template>
			</el-table-column>
			<el-table-column
				label="提现中金额"
				prop="frozenBalance"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="余额"
				prop="availableBalance"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
		</el-table>
		<!-- 分页 -->
		<Pagination
			v-show="total > 0"
			:total="total"
			:page.sync="pageNo"
			:limit.sync="pageSize"
			@pagination="getList"
		/>
	</div>
</template>
<script>
import { FinancialToolsApi, sumList } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		queryParams: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		return {
			pageNo: 1,
			pageSize: 10,
			loading: false,
			total: 0,
			dataList: [],
			selectRow: {},
			showCustomForm: false,
			isAdd: false,
			totalIncome: 0,
			frozenBalance: 0
		};
	},
	mounted() {},
	created() {},
	watch: {
		queryParams: function() {
			this.pageNo = 1;
			this.getList();
		}
	},
	methods: {
		async getList() {
			this.loading = true;
			FinancialToolsApi.agentAccount
				.sumList(this.queryParams)
				.then(res => {
					if (res.success) {
						this.totalIncome = res.data.availableBalance;
						this.frozenBalance = res.data.frozenBalance;
					}
				});
			let result = await FinancialToolsApi.agentAccount.list(
				this.pageNo,
				this.pageSize,
				this.queryParams
			);
			console.log("result----->126", result);
			this.loading = false;
			this.total = result.count || 0;
			this.dataList = result.data || [];
		},
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.getList();
		},
		handleStatusChange(row) {
			let text = row.status === "DISABLE" ? "禁用" : "启用";
			this.$confirm("是否确定" + text + "?", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(async () => {
					let result = await FinancialToolsApi.agentAccount.edit(
						row.accountNo,
						{
							state: row.state
						}
					);
					if (result.success) {
						this.getList();
					}
				})
				.catch(() => this.getList());
		},
		async handleUpdate(row) {
			this.showCustomForm = true;
			this.selectRow = row;
			this.isAdd = false;
		},
		handleDelete(row) {
			this.$confirm(
				"是否确定删除账户编号为" + row.accountNo + "的数据项}?",
				"警告",
				{
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}
			).then(async () => {
				let result = await FinancialToolsApi.agentAccount.remove(
					row.accountNo
				);
				if (result.success) {
					this.Message.success("删除成功");
					this.getList();
				}
			});
		}
	}
};
</script>

<style scoped>
.huizong {
	margin-right: 50px;
}
</style>
